<template>
  <div>
    <v-expansion-panels
      class="expansion-wrapper"
      :class="{ 'hb-elevation-medium' : openPanel }"
      style="border: 1px solid #c4cdd5; box-shadow: none"
      :value="openPanel ? 0 : 1"
      @change="openClosePanel()"
      :readonly="addReportCollection ? true : false"
    >
      <report-card class="report-card-background" :reportNameLength="report.name.length" :id="(collectionName !== 'My Pinned Reports') ? 'searchResult' + report.id + collectionScrollId : '' " :is-share="isShare">
        <template v-slot:title :id="report.id">
          <hb-tooltip>
            <template slot="item">
              <div class="d-flex">
                <div>
                  <hb-checkbox
                    v-if="isMultiple && (addReportCollection !== collectionName)"
                    v-model="checkbox"
                    @click.stop.native="check(checkbox)"
                    :disabled="disableCheckbox"
                  />
                </div>
                <div>
                  <span class="report-name">{{ report.name }}</span>
                </div>
              </div>
            </template>
            <template slot="body">
              <div style="max-width: 200px">
                {{ report.description }}
              </div>
            </template>
          </hb-tooltip>
        </template>
        <template v-slot:actions>
          <hb-link
            color="destructive"
            v-if="!reportInModal ? reportCount > 1 || (scheduleFlag && ScheduleEdit) : false"
            @click="check(false, true)"
            class="remove-button"
          >
            Remove
          </hb-link>
          <hb-btn
            v-if="!isShare"
            icon
            @click.native.stop="pinUnpinReportfun(report)"
            :mdi-code="report.pinned ? 'mdi-custom-pin' : 'mdi-custom-unpin'"
            :tooltip="report.pinned ? 'Pinned' : 'Unpinned'"
            active-state-off
          >
          </hb-btn>
          <hb-menu options align-right v-if="!isShare" class="pr-1">
            <v-list>
              <v-list-item
                v-if="report.report_category == 'static'"
                @click="downloadReport(report)"
              >
                <v-list-item-title>Run Report</v-list-item-title>
              </v-list-item>
              <v-list-item v-else @click="goToReport(report)">
                <v-list-item-title>Run Report</v-list-item-title>
              </v-list-item>

              <v-list-item @click="OpenReportShareModel()">
                <v-list-item-title>Share Report</v-list-item-title>
              </v-list-item>

              <v-list-item @click="OpenReportScheduleModel()">
                <v-list-item-title>Setup Repeat Delivery</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!report.pinned"
                @click="pinUnpinReportfun(report)"
              >
                <v-list-item-title>Pin Report</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="report.pinned"
                @click="pinUnpinReportfun(report)"
              >
                <v-list-item-title>Unpin Report</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="report.is_custom == 1"
                @click="deleteReport(report)"
              >
                <v-list-item-title>Delete Report</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="collectionName=='Bankers Box' || collectionName=='Investor Reports'"
                @click="removeReportCollection(report.id, collectionId,report.name)"
              >
                <v-list-item-title>Remove Report</v-list-item-title>
              </v-list-item>
            </v-list>
          </hb-menu>
        </template>
        <template v-slot:content>
          <v-row>
            <v-col md-4 class="pr-0 label-report-name"
              >Report Type:
              <span class="text-color-disabled ml-2">{{
                report.report_category !== "static" ? "Dynamic" : "Static"
              }}</span></v-col
            >
            <v-col md="4" class="my-0 py-0"></v-col>
          </v-row>
          <v-row
            v-if="
              (report.report_category == 'static' ||
                reportTimePeriod.length > 0) &&
                (report.is_custom !== 1) && !(report.name == 'Auction List' && report.original_collection_name == 'Delinquency Reports')
            "
            :class="[
              reportTimeSelected && reportTimeSelected.length
                ? 'report-time-field'
                : ''
            ]"
          >
            <v-col
              md-4
              class="pt-0"
              :class="[report.report_category == 'static' ? 'pb-3 ' : '']"
            >
              <label for="ReportTimePeriod" class="report-label">Report Time Period</label>
              <hb-select
                :items="reportTimePeriod"
                v-model="reportTimeSelected"
                hide-details
                dense
                :clearable="false"
                background-color="white"
                placeholder="Report Time Period"
                @change="updateReportDetails('shareReportTimeSelected', $event)"
                @focus="handleSelectFocus"
                @blur="handleSelectBlur"
                :full="true"
                style="padding-top:2px;"
              >
              </hb-select>
            </v-col>
          </v-row>

          <div
            class="d-flex report-date-block"
            :class="[reportTimeSelected == 'Custom Date' ? 'mb-8' : 'mb-4']"
            v-if="
              reportTimeSelected &&
                ['Custom Date', 'Custom Range'].includes(reportTimeSelected)
            "
          >
            <div class="report-date-container" :class="reportTimeSelected === 'Custom Range' && 'report-date-fullwidth'">
              <report-date-picker
                v-model="date"
                :solo="false"
                :removePaddingMargin="true"
                flat
                hide-details
                dense
                @change="updateReportDetails('start_date',$event)"
                @onfocus="handleSelectFocus"
                @onblur="handInputToggle"
              >
              </report-date-picker>
            </div>

            <div class="to-label">
              <span v-show="reportTimeSelected === 'Custom Range'">to</span>
            </div>

              <div class="report-date-container" :class="reportTimeSelected === 'Custom Range' && 'report-date-fullwidth'">
                <span v-if="reportTimeSelected === 'Custom Range'">
                  <report-date-picker
                    v-model="end_date"
                    :min="date"
                    :solo="false"
                    :removePaddingMargin="true"
                    flat
                    hide-details
                    dense
                    @change="updateReportDetails('end_date',$event)"
                     @onfocus="handleSelectFocus"
                     @onblur="handInputToggle"
                  >
                  </report-date-picker>
                </span>
            </div>
          </div>

          <v-row v-if="showFileTypeField">
            <v-col
              class="placeholder-wrapper"
              md-4
              :class="hasGroupingProfile(report) ? '' : 'pt-0'"
            >
              <label for="FileType" class="report-label">File Type</label>
              <v-select
                :items="showFileTypeField ? filteredFilesTypes : [filteredFilesTypes[0]]"
                class="field-report file-type-select"
                item-text="label"
                item-value="value"
                flat
                hide-details
                v-model="FileType"
                :placeholder="!FileType ? 'Choose File Type' : ''"
                :full="true"
                :menu-props="{ contentClass: 'hb-elevation-large' }"
                :clearable="false"
                :error="isFieldError && isFieldError.length ? isFieldError.includes(report.name) : isFileTypeInValid"
                :class="{'fileTypeError':isFieldError && isFieldError.length ? isFieldError.includes(report.name) : isFileTypeInValid}"
                @change="updateReportDetails('file_type', $event)"
                @focus="handleSelectFocus"
                @blur="handleSelectBlur"
                style="top:-2px;"
              >
                <template v-slot:selection="{ item, index }">
                  <span class="mr-1">{{ item.label }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row v-if="isPropertyGroups">
            <v-col class="pt-0 pb-0">
              <label class="report-label">Property Selection</label>
              <hb-radio-group 
                row 
                v-model="listSelection"
              >
                <HbRadio
                  label="Properties"
                  value="properties"
                />
                <HbRadio
                  label="Property Groups"
                  value="propertyGroups"
                />
              </hb-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="isComboBox">
            <v-col md-4 class="pt-0 pb-0">
              <div class="placeholder-wrapper pa-0">
                <label class="report-label" v-if="!isShare">Property</label>
                <v-combobox
                  v-if="!isShare"
                  v-model="dynamicProperty"
                  item-value="id"
                  clear-icon="mdi-close-circle"
                  :items="properties"
                  :error='isPropertyInValid'
                  :class="{'fileTypeError':isPropertyInValid}"
                  return-object
                  :placeholder="
                    !dynamicProperty.length ? 'Choose Property' : ''
                  "
                  multiple
                  :menu-props="{ contentClass: 'hb-elevation-large reports-property-selector-combobox-menu' }"
                  :clearable="properties.length === 1 ? false : true"
                  dense
                  class="report-combo-wrapper comobo-property-wrapper"
                  @focus="handlePropertyFocus"
                  @change="onChange(report, dynamicProperty[0] && dynamicProperty[0].id, index)"
                  style="position:relative;top:-8px;"
                  :filter="filterProperties"
                  :hide-no-data="!searchPropertry"
                  :search-input.sync="searchPropertry"
                >
                  <template v-slot:selection="{ item, parent }">
                    <hb-chip @click="parent.selectItem(item)" editable>
                      <span v-if="item.number">{{ item.number }} - </span>
                      <span v-else> - </span>
                      <span v-if="item.Address">
                        <span v-if="item.Address.city">{{ item.Address.city }} - </span>
                        <span v-if="item.Address.address">{{ item.Address.address }}</span>
                      </span>
                    </hb-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      @click="toggleProperty"
                      v-if="properties.length"
                    >
                      <v-list-item-action>
                        <v-icon :color="icon == 'check_box' || dynamicProperty.length > 0 ? '#00848E' : '#dfe3e8'">{{icon}}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{item, attrs}">
                    <v-list-item-action>
                      <v-icon v-if="attrs.inputValue">check_box</v-icon>
                      <v-icon v-else color="#dfe3e8">check_box_outline_blank</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="item.number">{{ item.number }} - </span>
                        <span v-else> - </span>
                        <span v-if="item.Address">
                          <span v-if="item.Address.city">{{ item.Address.city }} - </span>
                          <span v-if="item.Address.address">{{ item.Address.address }}</span>
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </div>
            </v-col>
          </v-row>
          <v-row v-else-if="listSelection == 'propertyGroups'">
            <v-col class="pt-0 mb-2">
              <label class="report-label">Property Group</label>
              <hb-select
                :items="filteredPropertyGroups"
                item-value="id"
                v-model="propertyGroupId"
                placeholder="Choose Property Group"
                v-validate="'required'"
                :error='isPropertyGroupInValid'
                @focus="handlePropertyGroupFocus"
                style="margin-top:1px;"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="item.name" class="mr-1"
                    >{{ item.name }}</span
                  >
                </template>
                <template v-slot:item="{ item, index }">
                  <span v-if="item.name" class="mr-1"
                    >{{ item.name }}</span
                  >
                </template>
              </hb-select>
            </v-col>
          </v-row>
          <v-row v-else-if="!isShare && !report.multiple_properties && report.report_category == 'static'">
            <v-col class="pt-0 mb-2">
              <label class="report-label">Property</label>
              <hb-autocomplete
                :items="properties" 
                item-value='id'
                v-model="property_id" 
                placeholder="Choose Property"
                v-validate="'required'"
                @change="onChange(report, property_id, index, collectionIndex)"
                :full="true"
                :clearable="properties.length === 1 ? false : true"
                :error="isPropertyInValid"
                @focus="handlePropertyFocus"
                @blur="handleSelectBlur"
                :filter="filterProperties"
                :hide-no-data="!searchPropertry"
                :search-input.sync="searchPropertry"
              >
                <template v-slot:selection="{ item }">
                  <span v-if="item.number" class="pr-1">{{ item.number }} - </span>
                  <span v-else class="pr-1"> - </span>
                  <span v-if="item.Address && item.Address.city" class="pr-1">{{ item.Address.city }} - </span>
                  <span v-if="item.Address.address">{{ item.Address.address }} </span>
                </template>
                <template v-slot:item="{ item }">
                  <span v-if="item.number" class="pr-1">{{ item.number }} - </span>
                  <span v-else class="pr-1"> - </span>
                  <span v-if="item.Address && item.Address.city" class="pr-1">{{ item.Address.city }} - </span>
                  <span v-if="item.Address.address">{{ item.Address.address }} </span>
                </template>
              </hb-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="hasGroupingProfile(report)" style="position:relative;top:-6px;">
            <v-col v-if="shouldShowGroupingProfiles" class="placeholder-wrapper pt-0">
              <label for="FileType" class="report-label">Grouping Profile</label>
              <v-select
                :items="spaceGroupList"
                item-value="id"
                item-text="name"
                class="field-report"
                flat
                hide-details
                :menu-props="{ contentClass: 'hb-elevation-large' }"
                :disabled="spaceGroupList.length == 0"
                v-model="unit_group_profile_id"
                :placeholder="
                  !unit_group_profile_id ? 'Choose Space Group' : ''
                "
                :full="true"
                @change="updateReportDetails('unit_group_profile_id_share', $event)"
                @focus="handleSelectFocus"
                @blur="handleSelectBlur"
                style="position:relative;top:-2px;"
              >
              </v-select>
            </v-col>
            <v-col v-else-if="!shouldShowGroupingProfiles" class="pt-0" >
              Default Grouping Profiles will be Selected
            </v-col>
          </v-row>

        </template>
        <template v-slot:share-footer v-if="!isShare">
          <hb-btn
            icon
            tooltip="Share"
            class="pr-2 ml-n1 my-n1"
            :active="showReportShareModal"
            @click="OpenReportShareModel"
          >
            mdi-user-actions-custom-1
          </hb-btn>
        </template>
        <template v-slot:cancel-footer v-if="!isShare">
          <hb-link @click="cancelReport()">
            Cancel
          </hb-link>
        </template>

        <template v-slot:action-footer v-if="!isShare">
          <hb-btn
            color="primary"
            v-if="report.report_category == 'static'"
            :loading="show_loading_spinner"
            @click="downloadReport(report)"
          >
            Run Report
          </hb-btn>
          <hb-btn color="primary" v-else @click="goToReport(report)">
            Run Report
          </hb-btn>
        </template>
      </report-card>
      <report-share-modal
        v-if="showReportShareModal"
        v-model="showReportShareModal"
        @close="showReportShareModal = false"
        :property="
          report.report_category !== 'static' || report.multiple_properties
            ? dynamicProperty
            : this.properties.filter(el => el.id == property_id)
        "
        :isEdit="false"
        :checkedReports="reportShareArray"
        :openForSchedule="openForSchedule"
        :collectionName="collectionName"
        @closeShareSchedule="showReportShareModal = false"
      >
      </report-share-modal>
    </v-expansion-panels>
    <hb-modal
      v-model="showDelete"
      v-if="showDelete"
      size="medium"
      title="Delete Report"
      @close="closeWindow"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          By deleting this report anyone this report has been shared will no longer be able to access. This includes yourself, co-workers, or
any receipients who have been scheduled to receive this report.<br><br>

          Are you sure you want to delete this report?
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="destructive" @click="deleteConfirm"
          >Delete Report</hb-btn
        >
      </template>
    </hb-modal>
  </div>
</template>
<script type="text/babel">
import moment from 'moment';
import {
  mapActions,
  mapGetters,
} from 'vuex';

import { notificationMixin } from '@/mixins/notificationMixin.js';

import api from '../../assets/api.js';
import { EventBus } from '../../EventBus';
import ReportCard from './Common/ReportCard.vue';
import ReportDatePicker from './Common/ReportDatePicker.vue';
import ReportShareModal from './Common/ReportShareModal.vue';

export default {
  name: "ReportAccordian",

  components: {
    ReportDatePicker,
    ReportCard,
    ReportShareModal
  },
  props: [
    "report",
    "collectionIndex",
    "index",
    "collectionName",
    "reportScrollId",
    "collectionScrollId",
    "isMultiple",
    "indeterminateCheckbox",
    "isShare",
    "reportDetails",
    "reportCount",
    "scheduleFlag",
    "ScheduleEdit",
    "emptySpaceGroupFlag",
    "isScheduleEdit",
    "isFieldError",
    "shareCollectionFlag",
    "reportInModal",
    "openSharingReports",
    "collectionId",
    "checkedCollections"
  ],
  mixins: [notificationMixin],
  data() {
    return {
      searchPropertry: null,
      staticReport: true,
      propertyId: "",
      FileTypes: [
        { label: ".pdf", value: "pdf" },
        { label: ".xlsx", value: "xlsx" }
      ],
      FileType: "",
      reportDate: "",
      reportEndDate: "",
      reportGroupID: "",
      showDelete: false,
      selected: {},
      property_id: "",
      unit_group_profile_id: "",
      date: moment().format("YYYY-MM-DD"),
      today: moment().format("YYYY-MM-DD"),
      yesterday: moment()
        .subtract(1, "day")
        .format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      show_loading_spinner: false,
      propertyIDArray: [],
      dynamicProperty: [],
      reportTimeSelected: null,
      reportDateInput: null,
      panel: [],
      cancelPanel: false,
      headerExpanend: false,
      showReportShareModal: false,
      reportTimePeriod: [],
      openPanel: false,
      checkbox: null,
      reportShareDetails: null,
      reportShareArray: null,
      dynamicFilter: null,
      openForSchedule: false,
      isFileTypeInValid:false,
      isPropertyInValid:false,
      spaceGroupArray: [],
      disableCheckbox: false,
      listSelection: 'properties',
      propertyGroupId: '',
      isPropertyGroupInValid: false,
      osrErrorDescription: 'Following properties have a missing or multiple default space groups.',
      osrErrorNote: `Note: You can update the default space group from Settings > Space Groups. There should be only one default space group per property.`
    };
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      getStoreSpaceGroup: "reportLibraryStore/getStoreSpaceGroup",
      addReportCollection: 'reportLibraryStore/getAddReportCollection',
      customCollReports: 'reportLibraryStore/getCustomCollReports',
      primaryRoleId: 'authenticationStore/getPrimaryRoleId',
      activeDynamicReport: "reportStore/getActiveDynamicReport",
      allProperties: 'propertiesStore/properties',
      propertyGroups: 'propertiesStore/propertyGroups',
      filteredPropertyGroups: 'propertiesStore/filteredPropertyGroups',
    }),
    selectAllProperty() {
      return this.properties.every(x => this.dynamicProperty.some(y => y.id === x.id));
    },
    icon() {
      if (this.dynamicProperty.length === 0)
        return "mdi-checkbox-blank-outline";
      if (this.selectAllProperty) return "check_box";
      if (this.dynamicProperty.length > 0) return "indeterminate_check_box";
      if (!this.selectAllProperty) return "check_box_outline_blank";
      return "mdi-checkbox-blank-outline";
    },
    showFileTypeField() {
      if(!(this.isShare || this.shareCollectionFlag) && (this.report.report_category === "dynamic" || this.report.report_category === null ))
      {
        return false;
      }

      return (
        this.report.download_xls ||
        ((this.isShare || this.shareCollectionFlag) && this.report.report_category !== "static")
      );
    },
    spaceGroupList() {
      if (this.getStoreSpaceGroup.length && this.property_id?.length) {
        const find = this.getStoreSpaceGroup.find(el =>  el.propertyId == this.property_id)
        this.spaceGroupArray = find ? find.spaceGroups : [];
      }
      this.report.space_groups = this.spaceGroupArray;
      return this.spaceGroupArray;
    },
    filteredFilesTypes(){
      return this.FileTypes.filter(item => ( (item.label === '.pdf' && this.report.download_pdf) || (item.label === '.xlsx' && this.report.download_xls) ));
    },
    isPropertyGroups() {
     return !!((this.report.multiple_properties || this.report.report_category !== 'static') && this.propertyGroups.length)
    },
    isComboBox() {
      if(this.listSelection === 'properties') {

        if(this.isPropertyGroups) return true;
        if(this.report.multiple_properties || this.report.report_category !== 'static') return true;
      }
      return false
    },
    shouldShowGroupingProfiles() {
      if(this.dynamicProperty.length < 2 && this.listSelection === 'properties') return true;
      return false;
    }
  },
  async created() {
    await this.setActiveDynamicReportProperties();
    EventBus.$on("open-panel", this.openPanelBySelect);
    EventBus.$on(`check-${this.collectionIndex}`, this.checkAllReports);
    EventBus.$on(`cancelMultipleCheckbox`, this.cancelCheckBox);
    EventBus.$on(`check-report-${this.report.id}`, this.checkReport);
    EventBus.$on("startShareCollectionFlag", this.startShareCollectionFlag);
    if(this.openSharingReports == this.report.collection_id && this.shareCollectionFlag) {
      this.openPanel = true;
    }
    if ((this.checkedCollections && this.checkedCollections.includes(this.report.id)) || (this.indeterminateCheckbox && this.indeterminateCheckbox[this.collectionIndex])) {
      this.checkbox = true;
      this.openPanel = !this.addReportCollection ? !this.openPanel : false;
    }
    if (this.customCollReports[this.addReportCollection] && this.customCollReports[this.addReportCollection].includes(this.report.id)) {
      this.checkbox = true;
      this.disableCheckbox = true;
    }
    await this.fetchStructure(true);
    // Report Share part
    if (this.reportDetails) {
      this.reportTimeSelected = this.reportDetails.shareReportTimeSelected;
      this.reportDateInput = this.reportDetails.profileSelected;
      this.date = this.reportDetails.start_date;
      this.end_date = this.reportDetails.end_date;
      this.FileType = this.reportDetails.file_type;
      this.unit_group_profile_id = this.reportDetails.unit_group_profile_id_share;
    }
  },
  updated() {
    if(this.emptySpaceGroupFlag) {
      this.spaceGroupArray = [];
      this.report.space_groups = [];
    }
  },
  destroyed() {
    EventBus.$off("check", this.checkAllReports);
    EventBus.$off("open-panel", this.openPanelBySelect);
    EventBus.$off(`cancelMultipleCheckbox`, this.cancelCheckBox);
    EventBus.$off(`check-report-${this.report.id}`, this.checkReport)
  },
  methods: {
    ...mapActions({
      updateReportDetailsState: "reportLibraryStore/updateReportDetailsState",
      pinUnpinReport: "reportLibraryStore/pinUnpinReport",
      deleteStoreReport: "reportLibraryStore/deleteStoreReport",
      removeReportCollectionStore: "reportLibraryStore/removeReportCollectionStore",
      updateActiveDynamicReport: "reportStore/updateActiveDynamicReport",
    }),
    changeProperty() {
      if (this.dynamicProperty.length) {
        this.dynamicProperty = this.dynamicProperty.filter((e) => typeof e !== "string");
      }
    },
    filterProperties(item, queryText, itemText) {
      let propertyNumber = item.number ? item.number.toLowerCase() : "";
      let propertyCity = item.Address && item.Address.city ? item.Address.city.toLowerCase() : "";
      let propertyAddress = item.Address && item.Address.address ? item.Address.address.toLowerCase() : "";
      const name = propertyNumber + " - " + propertyCity + " - " + propertyAddress;
      const searchText = queryText.toLowerCase();
      return name.indexOf(searchText) > -1;
    },
    startShareCollectionFlag() {
      this.isPropertyInValid = false;
    },
    handleSelectFocus(){
      // this.$emit('handleSelectFocus',this.report.name)
      // this.isFileTypeInValid = false;
    },
    handlePropertyFocus(){
      this.isPropertyInValid = false
    },
    handlePropertyGroupFocus(){
      this.isPropertyGroupInValid = false
    },
    handleSelectBlur(){
      this.$emit('handleSelectBlur')
    },
    handInputToggle(event){
      this.$emit('handInputToggle')
    },
    updateReportDetails(key, value, isIntermidiate) {
      if(key == "file_type" && value.length) {
        this.$emit('handleSelectFocus',this.report.name)
        this.isFileTypeInValid = false;
      }
      if (key == 'shareReportTimeSelected' && !['Custom Date', 'Custom Range'].includes(this.reportTimeSelected)) {
        this.date = moment().format("YYYY-MM-DD");
        this.updateReportDetails('start_date', this.date, true)
        this.end_date = moment().format("YYYY-MM-DD");
        this.updateReportDetails('end_date', this.end_date, true)
      } else if(this.reportTimeSelected === 'Custom Range' && moment(this.date).isSameOrAfter(this.end_date) || this.reportTimeSelected === 'Custom Date') {
        this.end_date = this.date;
        if(this.reportDetails)  this.reportDetails.end_date = this.date;
      }
      if(this.reportDetails) {
        this.reportDetails[key] = value;
      }
      else if (!isIntermidiate) {
        this.updateReportDetailsState(this.getReportShareObject());
      }
     if(!this.reportDetails) {
        EventBus.$emit("fetchCheckboxId", this.getReportShareObject(), this.checkbox);
      }
    },

    openPanelBySelect(id){
      if (this.reportScrollId  === this.report.id && this.collectionIndex != 0) {
        this.openPanel = !this.addReportCollection ? true : false;
      }
    },
    openClosePanel() {
      this.openPanel = !this.openPanel;
    },
    checkAllReports() {
      if(!this.disableCheckbox) {
        if (this.indeterminateCheckbox[this.collectionIndex] == true) {
          this.checkbox = true;
          this.openPanel = !this.addReportCollection ? true : false;
          EventBus.$emit(`check-report-${this.report.id}`, true);
        } else {
          this.checkbox = false;
          this.openPanel = false
          EventBus.$emit(`check-report-${this.report.id}`, false);
        }
      }
    },
    async check(check, isRemove) {
      if (!this.disableCheckbox){
        this.openPanel = !this.addReportCollection ? true : false;
        if(this.isScheduleEdit && this.scheduleFlag) {
          this.$emit("removeViewReport", this.report.id)
        }
        if (!check) {
          this.checkbox = false;
          this.openPanel = false;
        }
        EventBus.$emit("fetchCheckboxId", this.getReportShareObject(), check);
        if (check == null) {
          this.$emit("unCheck", this.collectionIndex);
        }
        EventBus.$emit(`check-report-${this.report.id}`, check);
        if (isRemove) {
          this.$emit('removeReport', this.report);
        }
      }
    },
    checkReport(value){
      if (!this.disableCheckbox){
        this.checkbox = value;
      }
    },
    cancelReport() {
      this.openPanel = false;
    },
    async fetchStructurePre(id) {
      if (this.report.id === id) {
        await this.fetchStructure(true);
      }
    },
    async fetchStructure(isSearch) {
      if (
        this.report.report_category !== "static" &&
        !this.reportDateInput &&
        this.report.is_custom !== 1
        //  &&
        // (isSearch || !this.openPanel)
      ) {
        await api
          .get(this, api.REPORTS + "structure", {
            template: this.report.template
          })
          .then(res => {
            if (
              res.config.filter_structure &&
              res.config.filter_structure.length > 0
            ) {
              this.reportDateInput = res.config.filter_structure[0].input;
            }
            this.dynamicFilter = res.config.filters;
          })
          .catch(err => {
            this.showMessageNotification({ type: "error", description: err });
          });
        this.setTimePeriodOptions();
      } else if (!this.reportTimePeriod || !this.reportTimePeriod.length) {
        this.setTimePeriodOptions();
      }
    },
    setTimePeriodOptions() {
      let profile1Options = [
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
        "Year To Date",
        "Custom Date",
        "Custom Range"
      ];
      let profile2Options = ["Today", "Yesterday", "Custom Date"];
      if (this.report.report_category === "static") {
        this.reportTimePeriod = this.report.end_date
          ? profile1Options
          : profile2Options;
        this.reportDateInput = this.report.end_date ? "timeframe" : "date";
        if (!this.reportTimeSelected)
          this.reportTimeSelected = this.report.end_date ? "This Month" : "Today";
      } else {
        if (this.reportDateInput === "date") {
          this.reportTimePeriod = profile2Options;
          if (!this.reportTimeSelected)
            this.reportTimeSelected = "Today";
        } else if (this.reportDateInput === "timeframe") {
          this.reportTimePeriod = profile1Options;
          if (!this.reportTimeSelected)
            this.reportTimeSelected = "This Month";
        } else return [];
      }
    },

    pinUnpinReportfun(report) {
      let payload = {
        report: report,
        name: this.collectionName
      };
      this.pinUnpinReport(payload);
    },
    onChange(report, property_id, index) {
      this.changeProperty();
      this.property_id = property_id;
      this.spaceGroupArray = [];
      this.unit_group_profile_id = "";
      const find = this.getStoreSpaceGroup.length ?
      this.getStoreSpaceGroup.find(el => el.propertyId == property_id) : undefined;
      if (find) {
        this.spaceGroupArray = find.spaceGroups;
      } else {
        this.$emit(
        "getGroupingProfiles",
        report,
        property_id,
        index,
        this.collectionIndex
        );
      }
      this.report.space_groups = this.spaceGroupArray;
    },
    dynamicPropertyChange() {
      this.propertyIDArray = [];
      this.propertyIDArray = this.dynamicProperty.map(el => el.id);
    },
    closeWindow() {
      this.showDelete = false;
      this.selected = {};
    },
    deleteReport(u) {
      this.selected = u;
      this.showDelete = true;
    },
    deleteConfirm() {
      api
        .delete(this, api.REPORTS, this.selected.id)
        .then(r => {
          this.$emit(
            "setUpdatedCollection",
            this.selected,
            this.index,
            this.collectionIndex
          );
          if (this.selected.pinned) {
            this.deleteStoreReport(this.selected);
          }
          this.$store.dispatch('reportStore/updateDeletedViews', this.selected.id)
          this.showMessageNotification({
            type: "success",
            description:
              "You have deleted “" +
              this.selected.name +
              "” from your Custom Reports."
          });
        })
        .catch(err => {
          this.showMessageNotification({ type: "error", description: err });
        });
      this.showDelete = false;
    },
    hasGroupingProfile(report) {
      return typeof report.unit_group_profile_id !== "undefined";
    },
    async downloadReport(report) {
      this.$emit("resetShareCollectionFlag");

      if (!report.download_xls) {
        this.FileType = this.filteredFilesTypes[0].value;
      }
      let format = this.FileType;
      let fileArr=[];

      if(!this.date){
        fileArr.push('Please select date to view report.')
      }

      if(report.multiple_properties) {
        if(this.listSelection == 'properties' && !this.dynamicProperty?.length){
          fileArr.push('Please select Property to view the report.')
          this.isPropertyInValid = true;
        }
        else if(this.listSelection == 'propertyGroups' && !this.propertyGroupId){
          fileArr.push('Please select Property Group to view the report.')
          this.isPropertyGroupInValid = true;
        }
      }
      else {
         if(!this.property_id){
          fileArr.push('Please select Property to view the report.')
          this.isPropertyInValid = true;
        }
      }

      if(!this.FileType){
        fileArr.push('Please select File Type to view the report.')
        this.$nextTick(()=>{
          this.isFileTypeInValid = true;
        })
      }

      if(fileArr.length){
        return  this.showMessageNotification({
            description: "",
            list: fileArr,
          });
      }

      if (this.hasGroupingProfile(report) && !this.unit_group_profile_id && this.listSelection === 'properties' && this.dynamicProperty?.length === 1) {
        return this.showMessageNotification({
          type: "error",
          description: "Please enter all the fields to view this report"
        });
      }
      let timeZone = moment.tz.guess();

      let properties = this.dynamicProperty.map(el => el.id)
      let data = {
        type: report.template_type,
        format,
        ...(!report.multiple_properties && {property_id: this.property_id}),
        ...(!this.propertyGroupId && format === "pdf" && {properties}),
        ...(!this.propertyGroupId && format !== "pdf" && {property_ids: properties}),
        timeZone: timeZone,
        name: report.name,
        unit_group_profile_id: this.unit_group_profile_id,
        property_group_id: this.propertyGroupId,
        roles_id: [this.primaryRoleId]
      };

      if (!this.report.end_date) {
        data.date = this.date;
        data.label = this.reportTimeSelected;
      } else {
        (data.label = this.reportTimeSelected), (data.date = this.date);
        data.end_date = "";
        if (this.reportTimeSelected === "Custom Date") {
          data.date = this.date;
          data.end_date = this.date;
          data.label = "Custom Range";
        } else if (this.reportTimeSelected === "Custom Range") {
          data.date = this.date;
          data.end_date = this.end_date;
        }
      }
      this.errorClear(this.$options.name);

      let filename = report.name;
      if(this.propertyGroupId) {
        let property_group = this.propertyGroups.find(pg => pg.id == this.propertyGroupId);
        filename += ` - ${property_group.name}`
      }
      else if (report.multiple_properties && (properties?.length > 1))
        filename += ' - Multiple Properties';
      else {
        let pid = data.property_id || (properties?.length && properties[0]);
        let property = this.allProperties.find(p => p.id == pid);
        filename += ` - ${property.name}`
      }

      let report_period = this.getTimeframeDates({ label: this.reportTimeSelected, start_date: data.date, end_date: data.end_date });

      if(!this.report.end_date)
        filename += ` - ${report_period.start}`
      else
        filename += ` - (${report_period.start} - ${report_period.end})`;

      filename = filename.replace(/[/\\?.*:|"<>]/g, '');

      if (format === "pdf") {
        this.show_loading_spinner = true;
        try {
          let response = await api.post(
            this,
            api.REPORTS + "download-pdf",
            data,
            report.id,
            false
          );

          if (response.socket) {
            const payload = {
              type: 'document_download',
              title: 'Generating Document',
              persistent: true,
              loading: true,
              description: 'We are generating your document, it should begin downloading shortly.',
              progress: true
            }
            this.showMessageNotification(payload);
          } else {
            const arr = new Uint8Array(response.data);
            let blob = new Blob([arr], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
          }
          this.show_loading_spinner = false;
        } catch (err) {
          this.show_loading_spinner = false;
          this.parseAndShowError(err)
        }
      } else {
        this.show_loading_spinner = true;
        let url = api.REPORTS + "download-xlsx";
        try {
          let response = await this.$http.post(url, data, {
            responseType: "arraybuffer"
          });
          let blob = new Blob([response.data], {
            type: response.headers.get("content-type")
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          this.show_loading_spinner = false;
        } catch (err) {
          this.show_loading_spinner = false;
          this.showMessageNotification({
            description: err.statusText ? err.statusText : err
          });
        }
      }
    },
    getTimeframeDates(timeframe){

    if(!timeframe || !timeframe.label){
      return {
        start: null,
        end: moment().endOf('day').format('YYYY-MM-DD')
      }
    }
    switch(timeframe.label.toLowerCase()) {
      case 'today':
        return {
          start: moment().startOf('day').format('YYYY-MM-DD'),
          end: moment().endOf('day').format('YYYY-MM-DD')
        }
      case 'yesterday':
        return {
          start: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
        }
      case 'last 7 days':
        return {
          start: moment().subtract(6, 'day').startOf('day').format('YYYY-MM-DD'),
          end: moment().endOf('day').format('YYYY-MM-DD')
        }
      case 'last 30 days':
        return {
          start: moment().subtract(29, 'day').startOf('day').format('YYYY-MM-DD'),
          end: moment().endOf('day').format('YYYY-MM-DD')
        }
      case 'this month':
        return {
          start: moment().startOf('month').format('YYYY-MM-DD'),
          end: moment().endOf('day').format('YYYY-MM-DD')
        }
      case 'last month':
        return {
          start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'month').endOf('month').endOf('day').format('YYYY-MM-DD')
        }
      case 'year to date':
        return {
          start: moment().startOf('year').format('YYYY-MM-DD'),
          end: moment().endOf('day').format('YYYY-MM-DD')
        }

      case 'custom date':
      case 'custom range':
        return {
          start: timeframe.start_date ? moment(timeframe.start_date).startOf('day').format('YYYY-MM-DD') : '',
          end: timeframe.end_date ? moment(timeframe.end_date).endOf('day').format('YYYY-MM-DD') : ''
        }

      default:
        return {
          start: null,
          end: moment().endOf('day').format('YYYY-MM-DD')
        }
    }
  },
    async goToReport(report) {
      this.$emit("resetShareCollectionFlag");
      this.propertyIDArray = [];
      this.propertyIDArray = this.dynamicProperty.map(el => el.id);
      
      let validGroupIds = !!((this.listSelection == 'properties' && this.propertyIDArray.length) || (this.listSelection == 'propertyGroups' && this.propertyGroupId));

      if (validGroupIds || this.propertyIDArray.length) {
        try {
          let timePeriodConfig = {};
          if (this.reportDateInput === "date") {
            let reportDate =
              this.reportTimeSelected === "Today"
                ? this.today
                : this.reportTimeSelected === "Yesterday"
                ? this.yesterday
                : this.date;
            timePeriodConfig.report_date = reportDate;
          } else if (this.reportDateInput === "timeframe") {
            let start_date = "";
            let end_date = "";
            let label = this.reportTimeSelected;
            // Auction List does not require time period
            if (report.name == 'Auction List' && report.original_collection_name == 'Delinquency Reports') {
              label = "";
            }
            if (this.reportTimeSelected === "Custom Date") {
              (start_date = this.date), (end_date = this.date);
              label = "Custom Range";
            } else if (this.reportTimeSelected === "Custom Range") {
              (start_date = this.date), (end_date = this.end_date);
            }
            timePeriodConfig.report_period = {
              days: 0,
              period: "",
              relation: "",
              label,
              start_date,
              end_date
            };
          }

          let reportState = {
            report_template: this.report.template,
            report_id: this.report.id,
            propertyIDArray: this.propertyIDArray,
            timePeriodConfig,
            property_group_id: this.propertyGroupId
          };

          this.$store.commit("reportStore/setDynamicRunParam", reportState);
          this.updateActiveDynamicReport({id: report?.id, collection_id: report?.original_collection_id, propertyIDArray: this.propertyIDArray});

          // SET local storage to get data in new tab
          localStorage.setItem("run-report-state", JSON.stringify(reportState));

          let path =
            report.report_category == "dynamic"
              ? "reports-library/" + report.template_type
              : "reports-library/view/" + report.id;
          // Redirection to a new tab
          // let routeData = this.$router.resolve({
          //   path: path,
          //   query: { dynamicRun: true }
          // });
          // window.open(routeData.href, "_self");
          this.$router.push({
            path: path,
            query: { dynamicRun: true }
          })
        } catch (err) {}
      } else {
        let errorArr = []
        if(this.propertyGroups.length) {
          if(this.listSelection == 'propertyGroups'){
            errorArr.push('Please select Property Group to view the report.')
            this.isPropertyGroupInValid = true;
          }
          else if(this.listSelection == 'properties') {
            errorArr.push('Please select Property to view the report.')
            this.isPropertyInValid = true;
          }
        }
        else if(!this.property_id || !this.propertyIDArray.length){
          if(!this.property_id || !this.propertyIDArray.length){
            errorArr.push('Please select Property to view the report.')
            this.isPropertyInValid = true;
          }
          if(errorArr.length){
            return this.showMessageNotification({
                description: "",
                list: errorArr,
              });
          }
        }
      }
    },
    handleValidation(reportShareArray){
        let fileArr=[];
        const [{reportDetails,report}] = reportShareArray;
        this.isPropertyInValid = false;
         if(!reportDetails.file_type && this.showFileTypeField){
          fileArr.push('Please select File Type to view the report.');
          this.isFieldError.push(report.name)
         }
       if(fileArr.length){
          this.openPanel = true;
          return this.showMessageNotification({
            description:'',
            list: fileArr
          });
      }
      return true;
    },
    async OpenReportShareModel() {
      this.$emit("resetShareCollectionFlag");
      this.reportShareArray = [this.getReportShareObject()]
       if(this.handleValidation(this.reportShareArray)){
         this.showReportShareModal = true;
         this.openForSchedule = false;
       }
    },
    getReportShareObject() {
      this.report.collection_name = this.collectionName;
      let fileType;
      if (!this.report.download_xls  && this.report.report_category == "static") {
        fileType =  this.filteredFilesTypes[0].value;
      }
      return {
        reportDetails: {
          shareReportTimePeriod: this.reportTimePeriod,
          shareReportTimeSelected: this.reportTimeSelected,
          profileSelected: this.reportDateInput,
          start_date: this.date,
          end_date: this.end_date,
          file_type: this.FileType ? this.FileType : fileType,
          unit_group_profile_id_share: this.unit_group_profile_id,
          shareDynamicFilter: this.dynamicFilter
        },
        report: this.report
      };
    },
    async OpenReportScheduleModel() {
      this.$emit("resetShareCollectionFlag");
      this.reportShareArray = [this.getReportShareObject()];
      if(this.handleValidation(this.reportShareArray)){
        this.openForSchedule = true;
        this.showReportShareModal = true;
      }
      ;
    },
    toggleProperty() {
      this.$nextTick(() => {
        if (this.selectAllProperty) {
          this.dynamicProperty = [];
        } else {
          this.dynamicProperty = JSON.parse(JSON.stringify(this.properties));
        }
      });
    },
    cancelCheckBox() {
      this.checkbox = false;
      this.disableCheckbox = false;
    },
    removeReportCollection(reportId, collectionId, ReportName) {
      const collectionType = this.collectionName =="Bankers Box" ? "banker_box" : "investor_reports";
      const payload = {
        'collection_id':collectionId,
        'report_id':reportId,
        'collection_type':collectionType
      }
      api.post(this, api.REMOVE_REPORT, payload).then(r => {
          this.removeReportCollectionStore({reportId, collectionId, 'collectionName': this.collectionName});
          this.showMessageNotification({ type: "success", description: `You have removed ${ReportName} from the ${this.collectionName}`});
      }
      )
    },
    setActiveDynamicReportProperties() {
      let propertiesList = JSON.parse(JSON.stringify(this.properties));
      if(propertiesList.length === 1) {
        this.dynamicProperty = JSON.parse(JSON.stringify(this.properties));
      } else {
        if(this.activeDynamicReport?.propertyIDArray?.length) {
            this.dynamicProperty = propertiesList.filter(el => {
            if(this.activeDynamicReport.propertyIDArray.includes(el.id)){
              return el;
            }
          });
        }
      }
      this.propertyIDArray = [];
      this.propertyIDArray = this.dynamicProperty.map(el => el.id);
      this.property_id = this.propertyIDArray.length ? this.propertyIDArray[0] : "";

      if(propertiesList?.length === 1) {
        this.onChange(this.report, this.property_id, 0);
      }
    },
    parseAndShowError(err) {
      let msg = err.body?.msg
      if(this.isJsonString(msg))
      {
        let err_list = JSON.parse(msg)
        this.showMessageNotification({
          description: this.osrErrorDescription,
          list: err_list,
          note: this.osrErrorNote,
          persistent: true
        });
      }
      else {
        this.showMessageNotification({
          description: msg
        });
      }
    },
    isJsonString(value) {
      try {
        JSON.parse(value);
        return true;
      }
      catch(err) {
        return false
      }
    }
  },
  watch:{
    'addReportCollection'(){
      if(this.addReportCollection) {
        this.openPanel = false;
        if (this.customCollReports[this.addReportCollection] && this.customCollReports[this.addReportCollection].includes(this.report.id)) {
          this.checkbox = true;
          this.disableCheckbox = true;
        }
      } else {
        this.checkbox = false;
        this.disableCheckbox = false;
      }
    },
    async 'properties'() {
      await this.setActiveDynamicReportProperties();
    },
    listSelection(value) {
      if(value === 'properties') this.propertyGroupId = '';
    },
    propertyGroups(value) {
      if(!value?.length) this.listSelection = 'properties';
    }
  }
};
</script>
<style scoped lang="scss">
.date-picker > .v-input > .v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.report-name {
  font-weight: 500;
  font-size: 14px;
  color: #00848e;
  border-bottom: 1px dotted #101318;
  line-height: 150%;
  word-break: break-word;
}
.remove-link {
  font-weight:400;
  font-size: 14px;
  line-height:16px;
  padding-right: 12px;
}
.report-card-background {
  background: #ffffff;
}
.label-report-name {
  color: #637381;
  font-weight: 500;
  font-size: 14px;
}
.text-color-disabled {
  font-weight: 400;
  color: #101318;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 12px;
}

.double-date-field {
  margin-top: 1px !important;
}

.single-date-field {
  margin-top: 23px !important;
}

.no-date-field {
  margin-top: 1px;
}

.report-time-field {
  margin-top: 5px !important;
}

.name-space {
  margin-bottom: 1px;
}

.property-combobox {
  max-height: 63px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.to-label {
  padding-top: 7px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.placeholder-wrapper >>> .v-input input,
.placeholder-wrapper >>> .v-input label,
>>> .v-label.v-label--active,
.placeholder-wrapper .v-input.v-text-field--placeholder ::placeholder {
  color: #101318 !important;
}
.placeholder-wrapper >>>.v-input__icon--clear .v-icon.v-icon {
  font-size: 16px !important;
  color: #C4CDD5 !important;
}
.report-label {
  color: #637381 !important;
  font-size: 11px !important;
  padding-top: 2px !important;
}
.field-report {
  margin-top: -15px !important;
}
</style>
<style>
.expansion-wrapper .v-expansion-panel .v-divider{
  border-color:transparent;
}
.expansion-wrapper .v-expansion-panel-header--active + .v-divider{
  border-color: rgba(0, 0, 0, 0.12);
}

html div.expansion-wrapper .v-expansion-panel.v-item--active .v-text-field > .v-input__control > div[role='combobox']:before,html div.expansion-wrapper .v-expansion-panel.v-item--active .v-text-field > .v-input__control > div[role='combobox']:after{
  bottom: -6px;
}
html div.expansion-wrapper .v-expansion-panel.v-item--active .v-expansion-panel-content .v-expansion-panel-content__wrap hr.v-divider.theme--light{
  border-color: rgba(0, 0, 0, 0.12);
}
.report-combo-wrapper .v-input__icon--clear .v-icon.v-icon{
  font-size: 16px !important;
  color: #C4CDD5;
}
.file-type-select.v-input.field-report.v-input--has-state.v-input--hide-details.theme--light.v-text-field.v-text-field--solo-flat.v-text-field--is-booted.v-text-field--placeholder.v-select.error--text.fileTypeError
 .v-select__slot .v-select__selections input::placeholder
{
color:red !important;
}
.comobo-property-wrapper.v-input.report-combo-wrapper.v-input--has-state.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-select--is-multi.v-autocomplete.error--text.fileTypeError
 .v-select__slot .v-select__selections input::placeholder
{
  color:red !important;
}
.comobo-property-wrapper.v-input.report-combo-wrapper.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-select--is-multi.v-autocomplete
 .v-select__slot .v-select__selections input::placeholder
{
  color: #637381 !important;
}
.report-date-container{
  flex-basis: calc(100/12 * 17.5px);
}

.report-date-container.report-date-fullwidth{
  flex-basis: 100%;
}

.report-date-block .report-date-container.report-date-fullwidth:first-child{
   margin-right: 3px;
}

.report-date-block .report-date-container.report-date-fullwidth:last-child{
   margin-left: 3px;
}

div.report-date-container .v-input__control > .v-input__slot > .v-input__prepend-inner .v-icon{
   font-size: 18px !important;
}
div.report-date-container .v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot{
  border-color:#C4CDD5;
}
.remove-button {
  margin-right: 10px !important;
  margin-top: 14px !important;
}
.pin-unpin-button {
  padding-top: 5px !important;
}

.reports-property-selector-combobox-menu .v-list-item__action {
  margin: 8px 8px 8px 0 !important;
}

.reports-property-selector-combobox-menu .v-list-item__title {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 1px 0;
}

.reports-property-selector-combobox-menu .v-list-item.v-list-item--active .v-list-item__title {
  color: #101318 !important;
}

.reports-property-selector-combobox-menu .v-list-item, .reports-property-selector-combobox-menu .v-list-item.v-list-item--active.v-list-item--link::before {
  background-color: #ffffff !important;
}

.reports-property-selector-combobox-menu .v-list-item:hover::before {
  background-color: rgba(0, 0, 0, 0.87) !important;
}

.reports-property-selector-combobox-menu .v-list-item.v-list-item--active.v-list-item--link:hover::before {
  background-color: rgba(0, 0, 0, 0.33) !important;
}

</style>
